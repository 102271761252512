<template>
  <v-snackbar
    v-model="snackbar.visible"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  computed: {
    snackbar() {
      return this.$store.state.ui.snackbar
    }
  },

  methods: {
    // TODO: Check mutation
    ...mapMutations(['ui/closeSnackbar'])
  }
}
</script>
